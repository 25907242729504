import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/components/is-mobile-view';

export const FavoriteWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -210px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: white;
    height: 40px;
    width: 40px;
    color: ${props => props.active === true ? props.theme.color.primaryColor : 'black'};
    margin: 10px;
    .icon {
      font-size: 25px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  @media (min-width: ${isMobileBreakpoint}px) {
    margin-top: -233px;
  }
`;
