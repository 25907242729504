import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FavoriteWrapper } from 'ToolboxComponents/commons/buttons/favorite/favorite.style';
import { AccountContext, ThemeContext, WebappContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';

const Favorite = ({ product, setFavorite, fromPage }) => {
  const theme = useContext(ThemeContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [account] = useContext(AccountContext).usePath();

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (account !== undefined) {
      account.favorites.includes(product.id) === true
        ? setActive(true)
        : setActive(false);
    }

  }, [account]);

  if (whiteLabel.config.hasAccount === false) {
    return <></>
  }

  return (
    <FavoriteWrapper theme={theme} active={active}>
      <div className='wrapper' onClick={() => {
        setFavorite(active);
        switch (fromPage) {
          case 'listing': analytics.sendFavoriteListingInteractionsEvent(); break;
          case 'home': analytics.sendFavoriteHomeInteractionsEvent(); break;
          case 'product': analytics.sendFavoriteProductInteractionsEvent(); break;
          default: ;
        }
        }}>
        <span className={`icon adn adn-heart${active ? '' : '-outline'}`} />
      </div>
    </FavoriteWrapper>
  );
};

Favorite.propTypes = {
  product: PropTypes.object.isRequired,
  setFavorite: PropTypes.func.isRequired,
};

export default Favorite;
